<template>
    <div id="body">
      <base-navigation></base-navigation>
  
      <base-card>
        <div id="element-to-convert">
            <div class="row">
          <div class="col-lg-6">
            <img
              width="120"
              height="100"
              src="../../assets/shangilia_logo.png"
            />
          </div>

          <div class="col-lg-6">
          <small> Shangilia community Welfare </small> 
            <br />
        <small>   Benevolent welfare for members </small> 
            <br />
        <small>  Along shangilia road </small> 
            <br />
        <small>   www.shangiliawelfare.co.ke, shangiliawelfare@gmail.com </small> 

            <!-- {{ memberdetails }} -->
          </div>
        </div>
  
          <div class="row">
            <div class="col-lg-7">
              <small>Membership Report</small>
            </div>
            <div class="col-lg-5 text-info" >Date <small> {{ Date().toLocaleString('nl-NL').split("GMT")[0] }}</small></div>
          </div>
          <h7 class="text-primary">Revenue</h7>
          <div class="row">
            <div class="col-lg-4 text-primary">
              <small>Registration Fee:  <b class="text-danger">{{ total_registrationFee }}</b> </small>
            </div>
            <div class="col-lg-4 text-primary">
              <small>Buffer Amount:  <b class="text-danger"> {{ total_buffferAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-primary">
              <small>Total collected: <b class="text-danger">{{ total_registrationFee + total_buffferAmount + total_caseOneAmount +total_caseTwoAmount + total_caseThreeAmount+total_caseFourAmount + total_caseFiveAmount + total_caseSixAmount+ total_caseSevenAmount + total_caseEightAmount + total_caseNineAmount + total_caseElevenAmount + total_caseTwelveAmount + total_walletAmount - 6000}}</b> </small>
              <!-- <small>Total collected: <b class="text-danger">{{ total_registrationFee + total_buffferAmount + total_caseOneAmount +total_caseTwoAmount + total_caseThreeAmount+total_caseFourAmount + total_caseFiveAmount + total_caseSixAmount+ total_caseSevenAmount + total_caseEightAmount + total_caseNineAmount }}</b> </small> -->
            </div>
            <div class="col-lg-4 text-primary">
              <small>Registered Members: <b class="text-danger">{{ someMembers.length }}</b> </small>
            </div>
            <div class="col-lg-4 text-primary">
              <small>Forms filled: <b class="text-danger">{{ memberdetails.length }}</b> </small>
            </div>
            <div class="col-lg-4 text-primary">
              <small>Active Members: <b class="text-danger">{{ active_members.length }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 1 Total: <b class="text-danger">{{ total_caseOneAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 2 Total: <b class="text-danger">{{ total_caseTwoAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 3 Total: <b class="text-danger">{{ total_caseThreeAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 4 Total: <b class="text-danger">{{ total_caseFourAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 5 Total: <b class="text-danger">{{ total_caseFiveAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 6 Total: <b class="text-danger">{{ total_caseSixAmount }}</b> </small>
            </div>
             <div class="col-lg-4 text-secondary">
              <small>Case 7 Total: <b class="text-danger">{{ total_caseSevenAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 8 Total: <b class="text-danger">{{ total_caseEightAmount }}</b> </small>
            </div>
            <div class="col-lg-4 text-secondary">
              <small>Case 9 Total: <b class="text-danger">{{ total_caseNineAmount }}</b> </small>
            </div>

            <div class="col-lg-4 text-secondary">
              <small>Case 11 Total: <b class="text-danger">{{ total_caseElevenAmount }}</b> </small>
            </div>
            
            <div class="col-lg-4 text-secondary">
              <small>Case 12 Total: <b class="text-danger">{{ total_caseTwelveAmount }}</b> </small>
            </div>
            <!-- <div class="col-lg-4 text-secondary">
              <small>Case 7 Total: <b class="text-danger">{{ total_caseSevenAmount }}</b> </small>
            </div>
           -->
            <!-- <div class="col-lg-4 text-info" >Date <small> {{ Date().toLocaleString('nl-NL').split("GMT")[0] }}</small></div> -->
          </div>

          <br>
        


          <div class="col text-secondary">
              <small>Wallet Total: <b class="text-danger">{{ total_walletAmount -6000 }}</b> </small>
            </div>
  
          <br />
          <h7 class="text-primary">Expenses</h7>

      <div class="row">
        <div class="col text-secondary">
              <small> Total Expense: <b class="text-danger">{{ total_expense }}</b> </small>
            </div>
            <div class="col text-secondary">
              <small> Balance at Bank and Mpesa: <b class="text-danger">{{  (total_registrationFee + total_buffferAmount + total_caseOneAmount +total_caseTwoAmount + total_caseThreeAmount+total_caseFourAmount + total_caseFiveAmount + total_caseSixAmount+ total_caseSevenAmount + total_caseEightAmount + total_caseNineAmount + total_caseElevenAmount + total_caseTwelveAmount + total_walletAmount)-total_expense  }}</b> </small>
            </div>

      </div>

      <div class="col">
       

      </div>

         <!-- payments {{ this.$store.getters.joiningMember(2) }} -->
  
          <!-- expenses {{ expenses }} -->

          <table class="table myFormat table-striped table-bordered table-sm">
            <thead>
              <tr>
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small>Item.</small></th>
                <th><small>Description</small></th>
                <!-- <th><small> Institution Name</small></th> -->
                <th><small>Amount</small></th>
                <th><small>Reference</small></th>
                <th><small>paid To</small></th>
                <th><small>date</small></th>
                
                <!-- <th><h6>Application date</h6></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="expense in expenses" :key="expense.id">
                <!-- <td>{{ payment.client_id}}</td> -->
                <td>
                  <small> {{ expense.item }}</small>
                </td>
                <td>
                  <!-- <small>{{ member.memberNames.split(" ")[0]  }}</small> -->
                  <small>{{ expense.description }}</small>

                </td>
  
                <td>
                  <small>{{ expense.amount }}</small>
                </td>
                <td>
                  <small>{{ expense.reference }}</small>
                </td>
                <td>
                  <small>{{ expense.paid_to }}</small>
                </td>
                <td>
                  <small>{{ expense.expense_date.split("T")[0] }}</small>
                </td>
  
                
  
                  
                    <!-- <td>
                    
                    <small>{{ this.$store.getters.joiningMember( Math.ceil(parseInt(member.membership_number)-1000))[0].case_4_amount }}</small>
                 
                  </td> -->
             

                
  
                <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              </tr>
            </tbody>
          </table>
          
          <br />
          <br />
         
          <div class="row">
            <br />
          </div>
        </div>
  
        
        <br>
       
        <button @click="exportToPDF">Download </button>
  
  
        <!-- <button @click="exportToPDF">Download</button> -->
        <br />
        <router-link to="/">Go back</router-link>
      </base-card>
    </div>
  </template>
  <script>
//   import axios from "axios";

  import html2pdf from "html2pdf.js";

  export default {
      
    methods: {
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [3, 15, 2, 15], //top, left, buttom, right
          filename: "Expenses" + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
    },
  
     

    computed: {
        memberdetails() {
              return  this.$store.getters.allMembers
  
              
          },
          someMembers(){


           return  this.$store.getters.newmembers 
          },
          membercases() {
              return  this.$store.getters.allcases
  
              
          },
          payments() {
            return  this.$store.getters.allPayments

            
        },
        active_members() {
            return  this.$store.getters.newmembers.filter((col) => col.buffer_amount == 2000).filter((col) => col.case_1_amount == 450).filter((col) => col.case_2_amount == 400).filter((col) => col.processed == 1)

            
        },
        
        memberPayments() {
            return  this.$store.getters.payments//.filter((col) => col.member_id == memberId)

            
        },
        total_registrationFee() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.registration_amount), 0);
      },
      total_buffferAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.buffer_amount), 0);
      },
      total_caseOneAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_1_amount), 0);
      },
      total_caseTwoAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_2_amount), 0);
      },
      total_caseThreeAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_3_amount), 0);
      },
      total_caseFourAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_4_amount), 0);
      },
            total_caseFiveAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_5_amount), 0);
      },
            total_caseSixAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_6_amount), 0);
      },
       total_caseSevenAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_7_amount), 0);
      },
      total_caseEightAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_8_amount), 0);
      },
      total_caseNineAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_9_amount), 0);
      },

      total_caseElevenAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_11_amount), 0);
      },

      total_caseTwelveAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.case_12_amount), 0);
      },

      //wallet

      total_walletAmount() {
        return this.someMembers
          .reduce((acc, item) => acc + parseInt(item.wallet_amount), 0);
      },
      
      

      //expenses 

      expenses() {
        return  this.$store.getters.expenses
      },
      total_expense() {
        return this.expenses
          .reduce((acc, item) => acc + parseInt(item.amount), 0);
      },

        


     
     
    },
    created() {
      this.$store.dispatch("Loadmembers");
      this.$store.dispatch('LoadPayments'); 
      this.$store.dispatch('LoadNewmembers');
      this.$store.dispatch('LoadExpenses');     
      

    
    },
  };
  </script>

<style scoped>
table.myFormat tr td { font-size: 12px; }
</style>